import React from "react"

import {GatsbyImage} from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Viewport from "../components/Viewport"

import {graphql, useStaticQuery} from "gatsby"
import {FaLinkedin, FaTwitter} from "react-icons/fa"

const Team = () => {
  const data = useStaticQuery(graphql`
    query {
      splix: file(relativePath: { eq: "splix.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      scco: file(relativePath: { eq: "scco.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      kan: file(relativePath: { eq: "kan.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  return (
    <Layout>
      <Seo title="Emerald Wallet Team" />

      <section className="team">
        <div className="description">
          <Viewport rootMargin="0px 0px -200px 0px">
            <h2>
              <span>Emerald</span> Team
            </h2>
            <h1>
              We are a small team of passionate developers, building the future
              of finance.
            </h1>
          </Viewport>
        </div>

        <div className="members">
          <Viewport threshold={0} rootMargin="0px 0px -200px 0px">
            <figure>
              <GatsbyImage image={data.splix.childImageSharp.gatsbyImageData} alt={"Igor Artamonov"}/>
              <figcaption>
                <h2>Igor Artamonov</h2>
                <p>CEO</p>
                <p>
                  <a href="https://www.linkedin.com/in/igorartamonov/">
                    <FaLinkedin /> LinkedIn
                  </a>
                </p>
                <p>
                  <a href="https://twitter.com/splix">
                    <FaTwitter /> @splix
                  </a>
                </p>
              </figcaption>
            </figure>
          </Viewport>

          <Viewport threshold={0} rootMargin="0px 0px -200px 0px">
            <figure>
              <GatsbyImage image={data.scco.childImageSharp.gatsbyImageData} alt={"Richard Schumann"}/>
              <figcaption>
                <h2>Richard Schumann</h2>
                <p>Creative Director, UX/UI Designer</p>
                <p>
                  <a href="https://twitter.com/schumanncombo">
                    <FaTwitter /> @schumanncombo
                  </a>
                </p>
              </figcaption>
            </figure>
          </Viewport>

          <Viewport threshold={0} rootMargin="0px 0px -200px 0px">
            <figure>
              <GatsbyImage
                image={data.kan.childImageSharp.gatsbyImageData}
                alt={"Ilya Kan"}
              />
              <figcaption>
                <h2>Ilya Kan</h2>
                <p>Head of Engineering</p>
              </figcaption>
            </figure>
          </Viewport>
        </div>
      </section>
    </Layout>
  )
}

export default Team
